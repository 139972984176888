.member-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 56px;
    padding-bottom: 56px;
}

.member-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #020202;
    padding-bottom: 16px;
}


.member-row {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

@media only screen and (max-width: 1415px) {
    .member-wrapper {
        padding-bottom: 100px;
    }
  }

  @media only screen and (max-width: 700px) {
    .member-wrapper {
        padding-top: 56px;
        padding-bottom: 56px;
    }
  }