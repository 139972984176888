.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
}

.content-book-now-link {
    display: flex;
    flex-direction:row;
    width: 100%;
    align-items: center;
    justify-content: left;
    gap: 4px;
}

.content-book-demo-button {
    color: #046B34;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

.content-checkIcon {
    width: 24px;
    height: 24px;
  }
  
  .book-now-link {
    display: flex;
    flex-direction:row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    gap: 4px;
  }

.content-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.content-image {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #046B34;
}

.sub-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #046B34;
}

.message {
    color: #020202;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 24px;
}

.book-now-button {
    color: #046B34;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
@media only screen and (max-width: 1416px) {
    .content-image-wrapper {
        width: 100%;
    }
    .content-wrapper {
        gap: 40px;
    }
    .text-wrapper {
        width: 100%;
        padding-left: 56px;
        padding-right: 56px;
    }
    .content-image {
        width: 75%;
        margin-bottom: 0px;
    }
  }

  @media only screen and (max-width: 768px) {
    .content-image-wrapper {
        width: 100%;
    }
    .content-wrapper {
        gap: 40px;
        padding-top: 80px;
    }
    .text-wrapper {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
    .title {
        font-size: 24px;
    }
    .sub-title {
        font-size: 20px;
    }
    .message {
        font-size: 18px;
    }
  }