.the-benefit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 126px;
    padding-bottom: 126px;
}

.component-sub-text {
    color: #020202;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
}

.component-sub-title-bold {
    color: #020202;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
}

.component-sub-title {
    color: #020202;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: normal;
}

.component-title {
    color: #046B34;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    padding-top: 24px;
}

.component-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.the-solution {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 56px;
    padding-bottom: 147px;
}

.the-problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 126px;
    padding-bottom: 126px;
}

@media only screen and (max-width: 1024px) {
    .the-problem  {
        align-items: flex-start;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 56px;
        padding-right: 56px;
    }
    .the-solution {
        align-items: flex-start;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 56px;
        padding-right: 56px;
    }
    .the-benefit {
        align-items: flex-start;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 56px;
        padding-right: 56px;
    }
    .component-title {
        text-align: left;
    }
    .component-sub-title {
        text-align: left;
    }
    .component-sub-text {
        text-align: left;
    }
  }

  @media only screen and (max-width: 768px) {
    .the-problem  {
        padding-left: 40px;
        padding-right: 40px;
    }
    .the-solution {
        padding-left: 40px;
        padding-right: 40px;
    }
    .the-benefit {
        padding-left: 40px;
        padding-right: 40px;
    }
    .component-title {
        font-size: 38px;
    }
    .component-sub-title {
        font-size: 22px;
    }
    .component-sub-text {
        font-size: 20px;
    }
  }