.advisors-wrapper {
    display: flex;
    overflow: hidden;
    background: #FEFEFE;
    width: 100%;
}

.advisors-content-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 128px;
}

.advisors-card-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
}

.advisors-text-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    gap: 15px;
    padding-top: 30px;
    flex-direction: column;
}

.advisors-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: #046B34;
}

.advisors-sub-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #020202;
    margin-top: 16px;
    text-align: center;
}

.advisor-data-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 537px;
    border-radius: 4px;
    padding: 80px 0px 0px 0px;
}

.advisor-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.card-image-description {
    display: flex;
    width: 100%;
}

.image-wrapper {
    padding-top: 24px;
    height: 108px;
}

.line-break {
    display: none;
}

.description-wrapper {
    height: 100%;
}

.attribute-wrapper {
    padding: 8px;
}

.attribute-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #020202;
}

.attribute-description {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #000000;
}

.attrubute-linkedin {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #046B34;
}

.advisor-comment {
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    overflow-wrap: break-word;
    text-align: center;
}

.advisor-comment-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    width: 100%;
    text-align: center;
}

.card-icon {
    width: 92px;
    height: 92px;
}

.advisor-name {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.advisor-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #9F9F9F;
    padding-top: 8px;
}

.image-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .advisors-content-wrapper {
        padding: 80px 56px;
    }
    .advisors-card-wrapper {
        gap: 56px;
    }
    .advisor-data-card {
        height: auto;
        padding: 0px;
    }
    .advisors-title {
        text-align: center;
        padding-bottom: 64px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .advisors-content-wrapper {
        padding: 80px 40px;
    }

    .advisors-title {
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
    }
    .advisor-title {
        text-align: left;
    }
    .advisors-sub-title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: left;
    }
    .advisor-data-card {
        height: auto;
        padding: 0px;
    }
    .advisor-comment-text {
        text-align: left;
        font-size: 16px;
    }
    .advisor-data-card {
        width: 100%;
    }
    .advisor-comment {
        text-align: left;
    }
    .advisors-text-wrapper {
        align-items: flex-start;
    }
    .image-title-wrapper {
        flex-direction: row;
        gap: 24px;
    }
    .name-title-wrapper { 
        align-items: flex-start;
    }
  }