.checkIcon {
  width: 34px;
  height: 34px;
}

.hero-book-now-link {
  display: flex;
  flex-direction:row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  gap: 4px;
}
.hero-wrapper {
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    height: 744px;
  }

  .hero-text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .hero-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 98px;
    text-align: center;
    color: #046B34;
    padding-left: 15%;
    padding-right: 15%;
  }

  .hero-description { 
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 35px;
    text-align: center;
    color: #020202;
    text-align: center;
    padding-top: 40px;
    padding-left: 15%;
    padding-right: 15%;

  }

  .book-demo-button {
    color: #046B34;
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }


@media only screen and (max-width: 1024px) {
  .hero-wrapper {
    height: 852px;
  }

  .hero-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 80px;
    font-weight: 700;
    text-align: left;
    padding-left: 56px;
    padding-right: 56px;
    line-height: normal;
  }

  .hero-description { 
    font-family: 'Montserrat', sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    padding-left: 56px;
    padding-right: 56px;
    line-height: normal;
  }

  .book-demo-button {
    font-size: 28px;
    line-height: normal;
  }
  .hero-book-now-link {
    justify-content: flex-start;
    padding-left: 56px;
  }
  .checkIcon {
    width: 28px;
    height: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .hero-wrapper {
    height: 655px;
  }

  .hero-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
    line-height: normal;
  }

  .hero-description { 
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    line-height: normal;
  }
  .book-demo-button {
    font-size: 22px;
    line-height: normal;
  }
  .checkIcon {
    width: 24px;
    height: 24px;
  }
  .hero-book-now-link {
    justify-content: flex-start;
    padding-left: 24px;
  }
}

