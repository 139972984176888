

.kiefa-icon-wrapper {
    width: 100%;
    position: relative;
}
.terms-message {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #9F9F9F;
    padding-top: 8px;
}

.policy-links {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #9F9F9F;
    text-decoration: none;
}

a:link, a:visited,  a:hover, a:active
{
    text-decoration: none;
}

.file-links {
    float: right;
    position: absolute;
    top: 10px;
    right: 0px;
}

.social-media-icon {
    margin: 10px;
}

.footer-content-wrapper {
    display: flex;
    flex-direction: row;
    padding: 48px 175px 48px 175px;
    background: #F9F8F6;
}

.footer-content-column {
    display: flex;
    flex-direction: column;
}

.footer-social-media-column {
    display: flex;
    flex-direction: column;
    gap: 36px;
}
@media only screen and (max-width: 1415px) {
    .footer-content-wrapper {
        padding: 56px;
    }
  }

  @media only screen and (max-width: 700px) {
    .footer-content-wrapper {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .footer-content-column {
        padding-top: 40px;
        align-items: center;
    }

    .terms-message {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal
    }
  }