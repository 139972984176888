.large-image {
    display: flex;
    width: 85%;
    height: 100%;
}

.large-image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 66px;
}

@media only screen and (max-width: 1024px) {
    .large-image-wrapper {
        padding-bottom: 32px;
    }
    .large-image {
        width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .large-image-wrapper {
        padding-bottom: 66px;
    }
  }