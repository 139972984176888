.workflow-image {
    width: 643px;
    height: 100%;
    flex-shrink: 0;
}

.workflow-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}

.tab-wrapper {
    display: flex;
    flex-direction: column;
    width: auto;
}

.tab {
    display: flex;
    flex-direction: row;
    height: 168px !important;
    width: 600px !important;
    max-width: 100% !important;
    text-transform: none !important;
    text-align: left !important;
}

.tab-label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}

.tab-label-description {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding-top: 16px;
}

.workflow-container {
    width: 100%;
    height: 100%;
    padding: 128px 0px 128px 0px;
}

.tabPanel {
    width: 650px;
    height: 850px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ai-image-wrapper {
    position: relative;
}

.workflow-title-wrapper {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 75px;
}

.workflow-title {
    font-family: 'Montserrat', sans-serif;
    width: 945px;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    color: #046B34;
}

.coa-gif {
    position: absolute;
    border-radius: 18px;
    width: 442px;
    height: 420px;
    left: 106px;
    bottom: 30px;
}

.workflow-mobile-view {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1415px) {
    .workflow-container {
        padding-top: 225px;
    }

    .MuiTabs-scroller {
        width: calc(100vw - 112px) !important;
    }

    .workflow-content-wrapper {
        flex-direction: column-reverse;
    }

    .workflow-title {
        width: 690px;
    }
}


@media only screen and (max-width: 700px) {
    .workflow-mobile-view {
        padding: 24px;
        gap: 56px;
        padding-top: 100px;
    }

    .workflow-title-wrapper {
        padding-bottom: 0px;
    }

    .coa-gif {
        width: 82%;
        height: 49%;
        left: 9%;
    }

    .workflow-title {
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
    }
    .workflow-content-wrapper {
        flex-wrap: unset;
        gap: 0px;
    }
    .workflow-image {
        width: 80%;
        height: auto;
    }
}