.integrations-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.integrations-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #020202;
}

.integrations-row {
    display: flex;
    flex-direction: row;
    gap: 80px;
}

.integrations-content-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 54px 54px 56px 54px;
}

@media only screen and (max-width: 1415px) {
    .integrations-content-wrapper {
        padding-left: 0px;
        padding-right: 0px;
    }
  }


  @media only screen and (max-width: 700px) {
    .integrations-row {
        padding-top: 25px;
        flex-direction: column;
    }
  }