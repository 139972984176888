.book-now-text {
  color: #046B34;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: capitalize;
}

.mobile-button-row {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
}

.mobile-blog-button-row {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  justify-content: flex-start;
}

.mobile-nav-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: #046B34;
}

.nav-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  color: #020202;
}

.nav-item:hover {
  color: #046B34 !important;
  font-weight: 600;
}

.mobile-sub-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  padding-left: 16px;
}

.sub-menu-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid transparent;
  border-radius: 0px 0px 4px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  width: 120px;
  padding: 16px;
}

.use-cases-link {
  color: #046B34;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.use-cases-button {
  color: #046B34;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.use-cases {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
}

.contact-us-button {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background: #FEFEFE;
  border: 1px solid #FEFEFE;
  border-radius: 4px;
  padding: 8px 8px;
  gap: 8px;
  color: #046B34;
  width: 170px;
  height: 36px;
  text-transform: capitalize;
  text-align: center;
}

.blog-button {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  background: #FEFEFE;
  border: 1px solid #FEFEFE;
  border-radius: 4px;
  padding: 8px 16px;
  gap: 8px;
  color: #046B34;
  width: 170px;
  height: 36px;
  text-transform: capitalize;
  text-align: center;
}


.login-button {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background: #046B34;
  border-radius: 4px;
  color: #FEFEFE;
  padding: 8px 32px;
  gap: 8px;
  width: 120px;
  height: 36px;
  text-transform: capitalize;
}

.contactUsLink {
  max-width: 148px;
}

.kiefa-logo {
  padding-top: 24px;
  padding-bottom: 24px;
  height: 50px;
}

.drawer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 76px;
  background: #FEFEFE;
  width: 92%;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: space-between;
}

.use-cases-title {
  color: #020202;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.drawer-content-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 40px;
}

.drawer {
  display: flex;
  flex-direction: column;
  width: 100%;
}