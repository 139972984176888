.house-of-cultivar-image {
    width: 159px;
    height: 80px;
}
.galaxy-labs-image {
    width: 75px;
    height: 92px;
}
.good-day-image {
    width: 90px;
    height: 79px;
}

.justice-cannabis-image {
    width: 165px;
    height: 95px;
}

.images-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 80px;
    flex-wrap: wrap;
}

.client-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.client-label {
    color: #020202;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.client-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    gap: 24px;
    flex-wrap: wrap;
}

@media only screen and (max-width: 480px) {
    .images-row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
  }
  